import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import HeroHeader from '../components/heroHeader';
import Job, { JobInfoProps } from '../components/job';
import HeroImage from '../assets/close-collage.jpg';
import PropTypes from 'prop-types';
import * as jobsStyles from '../styles/jobs.module.scss';
import * as postStyles from '../styles/post.module.scss';

const Jobs = ({ data }) => {
  const { site, allAshbyJobPosting } = data;

  const totalCount = allAshbyJobPosting?.totalCount ?? 0;
  const jobs = allAshbyJobPosting?.edges.map(({ node }) => node);

  const Content = () => {
    if (totalCount) {
      return (
        <Fragment>
          <div className={jobsStyles.intro}>
            <p>
              We are hiring top talent to help us unify the world&apos;s sales
              calls and emails into one beautiful workflow and to keep crushing
              the world of sales software.
            </p>
          </div>
          <ul className={jobsStyles.jobslist}>
            {jobs.map((job) => (
              <Job key={job.id} job={job.info} />
            ))}
          </ul>
        </Fragment>
      );
    } else {
      return (
        <div className={jobsStyles.intro}>
          <p>There are no jobs currently advertised.</p>
        </div>
      );
    }
  };

  return (
    <Layout>
      <Helmet defer={false}>
        <title>Jobs at Close | {site.siteMetadata.title}</title>
      </Helmet>
      <HeroHeader
        title="Engineering & Product Jobs at Close"
        description="We're building the sales communication platform of the future."
      />
      <Link to="https://close.com/about/">
        <img
          src={HeroImage}
          className={jobsStyles.image}
          alt="Collage of the Close Team"
          loading="eager"
          layout="fullWidth"
        />
      </Link>
      <div className={postStyles.content}>
        <h2>
          At Close, we&apos;ve built a next-generation CRM that eliminates
          manual data entry and helps sales teams close more deals.
        </h2>
        <Content />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query JobsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allAshbyJobPosting(
      filter: { departmentName: { in: ["Engineering", "Product"] } }
    ) {
      edges {
        node {
          id
          info {
            applyLink
            employmentType
            locationName
            teamName
            title
          }
        }
      }
      totalCount
    }
  }
`;

export default Jobs;

Jobs.propTypes = {
  data: PropTypes.shape({
    allAshbyJobPosting: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            info: JobInfoProps,
          }),
        })
      ),
      totalCount: PropTypes.number,
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};
