import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import * as styles from '../styles/job.module.scss';

const Job = ({
  job: { applyLink, employmentType, localName, teamName, title },
}) => {
  return (
    <li className={styles.job}>
      <h3 className={styles.title}>
        <Link to={applyLink}>{title}</Link>
      </h3>
      <ul className={styles.meta}>
        <li className={styles.metaItem}>
          {EMPLOYMENT_TYPE_LABELS[employmentType]}
        </li>
        <li className={styles.metaItem}>{teamName}</li>
      </ul>
    </li>
  );
};

export default Job;

const EMPLOYMENT_TYPE_LABELS = {
  FullTime: 'Full-Time',
  PartTime: 'Part-Time',
  Intern: 'Intern',
  Contract: 'Contract',
  Temporary: 'Temporary',
};

export const JobInfoProps = PropTypes.shape({
  applyLink: PropTypes.string,
  employmentType: PropTypes.oneOf(Object.keys(EMPLOYMENT_TYPE_LABELS)),
  localName: PropTypes.string,
  teamName: PropTypes.string,
  title: PropTypes.string,
});

Job.propTypes = {
  job: JobInfoProps,
};
